.individual-square {
    height: 90px;
    width: 90px;
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;
    color: #000000;
}

.individual-square > p {
    font-size: 15px;
    display: block;
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    margin-left: 2px;
}

.individual-square > img {
    margin-left: 13px;
    margin-top: 13px;
}

.selected-square {
    background-color: #F0F8FF !important;
}